
cssPrefix = ->
    (property, value, css={}) ->
        pfx = ['-webkit-', '-moz-', '-ms-', '-o-', '']

        if property is 'transform' and angular.isObject(value)
            x = y = z = '0px'
            x = value.x if 'x' of value is true
            y = value.y if 'y' of value is true
            z = value.z if 'z' of value is true
            value = 'translate3d('+x+', '+y+', '+z+')'

        angular.forEach pfx, (prefix) ->
            css[prefix+property] = value
            return
        css

angular.module 'app'
.filter 'cssPrefix', cssPrefix